import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { ViewsModule } from '@views/views.module';
import { HelpPanelModule } from '@shared/modules/help-panel/help-panel.module';

import { AuthGuard } from './auth.guard';
import { AuthInterceptor, authInterceptorProviders } from './shared/services/Auth.Interceptor/auth.Interceptor';
import { AuthModule,} from 'angular-auth-oidc-client';
import { HashLocationStrategy, CommonModule } from '@angular/common';
import { loadConfig } from './shared/services/auth-service/auth.service';
import { RouterHistoryService } from '@shared/services/router-history/router-history.service';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { MessageService } from './shared/services/message-service/message.service';
import { OrgService } from './shared/services/org-service/org.service';
import { RestApiService } from '../app/shared/services/rest-api/rest-api.service';
import { ToastrMessageService } from '../app/shared/services/toastr-message/toastr-message.service';
import { RouterModule } from '@angular/router';
import { GraphQLModule } from './graphql.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { TrackApiModule } from './api/track/track-api.module';
import { InHomeAwvApiModule } from './api/ih-awv/in-home-awv-api.module';
import { Access2ApiModule } from './api/access2/access-2-api.module';
import { CareOrchApiModule } from './api/care-orch/care-orch-api.module';
import {
  CARE_ORCH_API_INTERCEPTOR_PROVIDER,
  CareOrchApiInterceptor
} from '@shared/services/care-orchestration/care-orch-intercepter.service';
import { UserDetailApiModule } from '@api/user-detail/user-detail-api.module';
import { MixpanelService } from '@shared/services/mixpanel/mixpanel.service';
import { OverlayModule } from '@angular/cdk/overlay';

export const auditTrackUrl: string = `${environment.trackApiUrl.substr(
  0,
  environment.trackApiUrl.lastIndexOf('track')
)}logging/audit`;
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.heading = (text: string, level: number) => {
    switch (level) {
      case 1: {
        level = 1;
        break;
      }
      case 2: {
        level = 2;
        break;
      }
      case 3: {
        level = 3;
        break;
      }
      case 4: {
        level = 3;
        break;
      }
      case 5: {
        level = 3;
        break;
      }
      case 6: {
        level = 3;
        break;
      }
      default: {
        level = 1;
        break;
      }
    }
    return `
      <h${level} class="margin-top--none margin-bottom--small">
        ${text}
      </h${level}>`;
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TrackApiModule.forRoot({ rootUrl: environment.trackApiUrl }),
    UserDetailApiModule.forRoot({ rootUrl: environment.userDetailApiUrl }),
    InHomeAwvApiModule.forRoot({ rootUrl: environment.apiBaseUrl + '/ihawv' }),
    CareOrchApiModule.forRoot({ rootUrl: environment.careOrchApiUrl }),
    Access2ApiModule.forRoot({ rootUrl: environment.apiBaseUrl + '/access' }),
    AuthModule.forRoot({
      config: loadConfig()
    }),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    ViewsModule,
    HelpPanelModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory
      }
    }),
    RouterModule,
    GraphQLModule,
    OverlayModule,
    BrowserAnimationsModule,
    CommonModule,
    ToastrModule.forRoot(),
    UserIdleModule.forRoot({
      idle: environment.idleStart,
      timeout: environment.idleTimeout,
      ping: environment.idlePing
    })
  ],
  providers: [
    RouterHistoryService,
    HashLocationStrategy,
    RestApiService,
    MessageService,
    ToastrMessageService,
    OrgService,
    AuthGuard,
    authInterceptorProviders,
    AuthInterceptor,
    {
      provide: 'secureRoutes',
      useValue: [
        { tokenType: 'idToken', url: 'add yr URL here' },
        { tokenType: 'idtokenAndSubId', url: auditTrackUrl }
      ]
    },
    MixpanelService,
    CareOrchApiInterceptor,
    CARE_ORCH_API_INTERCEPTOR_PROVIDER
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

